import React from 'react';
import {Button, Card, Checkbox, Flex, Form, Input, Select, Space, Spin} from "antd";
import T from "../../common/t";
import UnitTypesMethods from "../../../methods/unitTypes.methods";
import StockMethods from "../../../methods/stock.methods";

const AddUnitTypeFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);

    const [locations, setLocations] = React.useState([]);

    const [checkedLocksId, setCheckedLocksId] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const [
                locations,
            ] = await Promise.allSettled([
                StockMethods.getAll(),
            ]);
            if (
                locations.status === 'fulfilled'
            ) {
                const arr = []
                Object.values(locations.value.data).map(item => {
                    arr.push({value: item.id, label: item.address})
                })
                setLocations(() => arr)
            }
        })()
    }, [])

    const checkedLocks = (id) => {
        if (checkedLocksId.includes(id)){
            setCheckedLocksId(() => checkedLocksId.filter(item => item !== id))
        } else {
            setCheckedLocksId(prev => [...prev, id])
        }
    }

    const handleOk = () => {
        setLoadingForm(true);
        UnitTypesMethods.addUnitType({...form.getFieldsValue(), stocks: checkedLocksId})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Тип для помещения успешно добавлен</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Заголовок</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    style={{width: '100%', marginBottom: '1rem',}}
                    label={<T>Локация</T>}
                    name={`stock`}
                >
                    <Space>
                        {
                            locations.map(loc =>
                                <Card key={loc.id}>
                                    <Checkbox onChange={() => checkedLocks(loc.value)} checked={checkedLocksId.includes(loc.value)}>
                                        {loc.label}
                                    </Checkbox>
                                </Card>
                            )
                        }
                    </Space>
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddUnitTypeFormComponent;
